.toggle > input{
    opacity: 0;
    position: absolute;
}

.slider{
    align-items: center;
    background-color: var(--color-secundary-02);
    border: .0625rem solid var(--color-primary-03);
    color: var(--color-primary-03);
    cursor: pointer;
    border-radius: 1.5rem;
    font-size: 1rem;
    font-weight: var(--font-weight-regular);
    display: inline-flex;
    height: 2.1875rem;
    letter-spacing: .01625rem;
    line-height: 1.5rem;
    width: 100%;
    padding: 0 1rem 0 3.25rem;
    position: relative;
    transition: color 0.2s ease-in-out;
    transition-delay: 0.2s;
}

.slider::before {
    background-color: var(--color-primary-03);
    border: none;
    border-radius: 50%;
    content: "";
    height: 1.25rem;
    left: 1rem;
    position: absolute;
    transition: all 0.2s linear;
    width: 1.25rem;
}

input:checked + .slider::before{
    background-color: var(--color-primary-05);
    left: calc(100% - 2.25rem);
}

input:checked + .slider{
    background-color: var(--color-primary-01);
    border: none;
    color: var(--color-primary-05);
    justify-content: flex-end;
    padding: 0px 3.25rem 0 1rem;
}

.slider.short{
    border-radius: .875rem;
    min-width: auto;
    padding: 0 0 0 .5rem;
    width: 4rem;
}

.slider.short::before{
    left: 8px;
    transition: all 0.1s linear;
}

input:checked + .slider.short{
    border-radius: .875rem;
    padding: 0 .5rem 0 0;
}

input:checked + .slider.short::before{
    left: 36px;
}