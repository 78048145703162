.react-calendar {
    width: 460px;
    max-width: 100%;
    background: var(--color-primary-05);
    border: 1px solid var(--color-primary-03);
    border-radius: 0.5rem;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    overflow: hidden;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .react-calendar button {
    margin: 0;
    outline: none;
  }

  .react-calendar button:enabled:hover {
    cursor: pointer;
  }

  .react-calendar__month-view__days__day--neighboringMonth:disabled,
  .react-calendar  button:disabled.react-calendar__month-view__days__day {
    cursor: not-allowed;
    color: transparent;
    background-color: var(--color-secundary-05);
    border: 1px solid var(--color-secundary-04) !important;
  }

  .react-calendar__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary-01);
    height: 2.75rem;
    margin-bottom: 1em;
    overflow: hidden;
  }

  .react-calendar__navigation button {
    min-width: 2.75rem;
    color: var(--color-primary-05);
    background: none;
    font-size: 1.25rem;
    border: 0;
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus,
  .react-calendar__navigation button[disabled] {
    background-color: var(--color-primary-01);
  }
  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button{
    font-size: 2.5rem !important;
    padding-bottom: 0.4375rem;
  }
  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button{
    display: none;
  }

  .react-calendar__navigation__label{
    text-transform: capitalize;
    max-width: 8rem;
    font-weight: var(--font-weight-semi-bold);
  }

  .react-calendar__month-view__weekdays {
    display: flex;
    justify-content: center;
    color: var(--color-primary-01);
    text-align: center;
    text-transform: capitalize;
    font-weight: bold;
  }

  .react-calendar__month-view__weekdays__weekday {
    max-width: 2.625rem !important;
    margin: 0 10px !important;
    font-size: 14px;
    color: var(--color-primary-03);
  }

  abbr{
    text-decoration:none !important;
  }

  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }

  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }

  .react-calendar__year-view{
    align-items: center;
    justify-content: center;
  }

  .react-calendar__month-view__days{
    align-items: center;
    justify-content: center;
  }

  .react-calendar__month-view__days__day{
    max-width: 2.625rem !important;
  }

  .react-calendar__month-view__days__day--weekend {
    color: var(--color-primary-03);
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: transparent !important;
    border: 1px solid var(--color-primary-03) !important;
  }

  .react-calendar__year-view__months{
    align-items: center;
    justify-content: center;
  }
  .react-calendar__year-view__months__month{
    max-width: 5.625rem !important;
  }

  .react-calendar__decade-view__years{
    align-items: center;
    justify-content: center;
  }

  .react-calendar__decade-view__years__year{
    max-width: 4.25rem !important;
  }

  .react-calendar__century-view__decades__decade{
    max-width: 5.9375rem !important;
  }

  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }

  .react-calendar__tile {
    border: 1px solid var(--color-primary-01);
    border-radius: 0.25rem;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    height: 1.5rem;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
    color: var(--color-primary-03);
    margin: 10px !important;
  }

  .react-calendar__tile:disabled {
    background-color: var(--color-primary-05);
  }

  .react-calendar__tile--now,
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus{
    background-color: transparent;
  }

  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus,
  .react-calendar__tile--hasActive,
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus,
  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: var(--color-primary-01);
    color: var(--color-primary-05);
  }

  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }
  